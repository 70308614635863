footer {
  color: #fff;
  background-color: #1c2237;
  /* display: flex; */
  /* float: left; */
  /* display: inline-block; */
}

.flex-row {
  display: flex;
  margin: 10px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ul {
  margin-bottom: 32px;
  flex-wrap: wrap;
  justify-content: center;
}

li {
  list-style: none;
  padding: 0 25px;
  margin-bottom: 16px;
}

.a {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
}

.i {
  cursor: pointer;
  font-size: 30px;
  text-align: center;
}
.p {
  text-align: center;
}
