.tech__section {
  padding: 0px 0 20px;
  display: flex;
  flex-direction: column;
  background: #fafafa;
  justify-content: center;
  align-items: center;
}

.tech__container-card {
  background: linear-gradient(45deg, #ffffff 0%, #ffffff 100%);
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2);
  width: 140px;
  height: 37px;
  border-radius: 2px;
  margin: 0 5px;
  margin-top: 25px;
}

.tech__container {
  display: flex;
  /* margin-bottom: -15px; */
  /* justify-content: center;
  align-items: center; */
}

.tech__heading {
  color: #1c2237;
  margin-bottom: -20px;
  margin-top: 20px;
  justify-content: center;
}

.tech__container-cardInfo {
  display: flex;
  color: #1c2237;
  margin-top: 4px;
  justify-content: center;
}

.tech__container-cardInfo h4 {
  font-size: 20px;
  margin-top: 5px;
  margin-left: 10px;
}

@media screen and (max-width: 610px) {
  .tech__container {
    display: grid;
    grid-gap: 5px 0px;
    flex-direction: column;
    grid-template-columns: auto auto auto;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 460px) {
  .tech__container {
    grid-template-columns: auto auto;
  }
}

@media screen and (max-width: 310px) {
  .tech__container {
    grid-template-columns: auto;
  }
}
